<template>
  <div class="details">
    <table>
      <thead>
        <th>No</th>
        <th align="left">Email</th>
        <th>Partially Vaccinated</th>
        <th>Fully Vaccinated</th>
        <th>Not Vaccinated</th>
      </thead>
      <tbody>
        <tr v-for="(record, index) in records" :key="record.email">
          <td align="center">{{ index + 1 }}</td>
          <td align="left">{{ record.email }}</td>
          <td align="center">
            <div
              class="circle success"
              v-if="record.firstdose && !record.seconddose"
            >
              Y
            </div>
          </td>
          <td align="center">
            <div
              class="circle success"
              v-if="record.firstdose && record.seconddose"
            >
              Y
            </div>
          </td>
          <td align="center">
            <div
              class="circle error"
              v-if="!record.firstdose && !record.seconddose"
            >
              Y
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "FullDetails",
  props: {
    records: {
      default: () => [],
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  margin-top: 20px;
}
.circle {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  color: transparent;
}
.success {
  background-color: lightseagreen;
}
.error {
  background-color: lighten(red, 20%);
}
th,
td {
  padding: 5px;
}
table,
th,
td {
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: lightgrey;
  border-collapse: collapse;
}
</style>
