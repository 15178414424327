<template>
  <form @submit="checkUser">
    <div>
      <input
        type="email"
        placeholder="you@yourcompany.com"
        id="email"
        class="input"
        v-model="email"
      />
    </div>
    <p v-if="noemail && !updated">Please input a valid email address</p>
    <p v-if="nouser && !updated">Email Does not Exist, Contact your HR!</p>
    <div class="button">
      <button type="submit" class="btn block">login</button>
    </div>
  </form>
</template>

<script>
import { domainref } from "@/firebase";
export default {
  name: "LoginForm",
  data() {
    return {
      email: "",
      nouser: false,
      noemail: false,
      updated: false,
    };
  },
  computed: {
    domain() {
      return this.email.split("@")[1];
    },
  },
  methods: {
    checkUser(e) {
      if (this.email) {
        const docref = domainref.doc(this.domain);
        docref
          .get()
          .then((doc) => {
            if (doc.exists) {
              if (doc.data()[this.email] !== undefined) {
                this.$emit("setEmployee", doc.data()[this.email]);
              } else {
                this.nouser = true;
              }
            } else {
              this.nouser = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.noemail = true;
      }
      e.preventDefault();
    },
  },
};
</script>

<style lang="scss" scoped></style>
