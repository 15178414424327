<template>
  <div class="container">
    <login-form @setEmployee="setEmployee" v-if="!employee.email"></login-form>
    <div v-if="employee.email && !updated">
      <p>Logged In as {{ employee.email }}</p>
      <form @submit="updateInfo">
        <div class="inputgroup">
          <input
            type="checkbox"
            id="firstdose"
            v-model="employee.firstdose"
            @change="employee.notdue = false"
          />
          <label for="firstdose">First Dose</label>
          <input
            type="checkbox"
            id="seconddose"
            v-model="employee.seconddose"
            @change="
              employee.notdue = false;
              employee.firstdose = true;
            "
          />
          <label for="seconddose">Second Dose</label>
          <input
            type="checkbox"
            id="notdue"
            v-model="employee.notdue"
            @change="
              employee.firstdose = false;
              employee.seconddose = false;
            "
          />
          <label for="notdue">Was Infected</label>
        </div>
        <div class="button">
          <button class="btn block" type="submit">update</button>
          <button class="btn outlined" @click="$router.go()">back</button>
        </div>
      </form>
    </div>
    <div v-if="updated">
      <p>Thank You, Details updated, Stay Safe!</p>
      <button class="btn block" @click="$router.go()">Go Back</button>
    </div>
    <Stats
      :domain="domain"
      :email="employee.email"
      v-if="domain && employee.email"
    />
  </div>
</template>

<script>
// @ is an alias to /src
import { domainref } from "@/firebase";
import Stats from "@/components/Stats.vue";
import LoginForm from "@/components/LoginForm.vue";
export default {
  name: "Tracker",
  components: {
    Stats,
    LoginForm,
  },
  data() {
    return {
      employee: {
        email: "",
        firstdose: false,
        seconddose: false,
        notdue: false,
      },
      updated: false,
    };
  },
  computed: {
    domain() {
      return this.employee.email.split("@")[1];
    },
  },
  methods: {
    setEmployee(e) {
      this.employee = e;
    },
    updateInfo(e) {
      domainref
        .doc(this.domain)
        .set({ [this.employee.email]: this.employee }, { merge: true })
        .then(() => {
          this.updated = true;
        });
      e.preventDefault();
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  margin: 1rem;
}
.inputgroup {
  margin: 3rem 1rem;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 768px) {
    justify-content: center;
  }
}
</style>
