<template>
  <div class="stats" v-if="records.length > 0">
    <div class="statsitem">
      <p class="statshead">Employees</p>
      <p class="statsdetail">{{ total }}</p>
    </div>
    <div class="statsitem">
      <p class="statshead">First Dose</p>
      <p class="statsdetail">{{ partial }}</p>
      <p class="percent">{{ ((partial / total) * 100).toFixed(2) }}%</p>
    </div>

    <div class="statsitem">
      <p class="statshead">Both Doses</p>
      <p class="statsdetail">{{ full }}</p>
      <p class="percent">{{ ((full / total) * 100).toFixed(2) }}%</p>
    </div>
    <div class="statsitem">
      <p class="statshead">Not Due</p>
      <p class="statsdetail">{{ notdue }}</p>
      <p class="percent">{{ ((notdue / total) * 100).toFixed(2) }}%</p>
    </div>
    <div class="statsitem">
      <p class="statshead">Not Updated</p>
      <p class="statsdetail">{{ notupdated }}</p>
      <p class="percent">{{ ((notupdated / total) * 100).toFixed(2) }}%</p>
    </div>
    <div>
      <input
        type="password"
        id="passphrase"
        v-model="passphrase"
        placeholder="enter passphrase to view employee-wise data"
        v-if="!showdetails"
      />
    </div>
    <full-details :records="sortedrecords" v-if="showdetails"></full-details>
  </div>
</template>

<script>
import { domainref } from "@/firebase";
import FullDetails from "./FullDetails.vue";
export default {
  components: { FullDetails },
  name: "Stats",
  props: {
    domain: {
      default: () => "",
      type: String,
    },
    email: {
      default: () => "",
      type: String,
    },
  },
  data() {
    return {
      records: [],
      passphrase: "",
    };
  },
  computed: {
    total() {
      return this.records.length;
    },
    partial() {
      return this.records.filter((item) => item.firstdose && !item.seconddose)
        .length;
    },
    full() {
      return this.records.filter((item) => item.firstdose && item.seconddose)
        .length;
    },
    notdue() {
      return this.records.filter((item) => item.notdue).length;
    },
    notupdated() {
      return this.records.filter(
        (item) => !item.notdue && !item.firstdose && !item.seconddose
      ).length;
    },
    sortedrecords() {
      const sorted = this.records;
      return sorted.sort((a, b) =>
        a.email < b.email ? -1 : a.email > b.email ? 1 : 0
      );
    },
    showdetails() {
      return this.passphrase === "venzojab2020";
    },
  },
  mounted() {
    domainref.doc(this.domain).onSnapshot((doc) => {
      const data = doc.data();
      this.records = Object.values(data);
      this.nouser = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.stats {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 5rem 0rem;
}
.statsitem {
  height: 100px;
  width: 150px;
  border: 2px solid lightseagreen;
  margin: 1rem 2rem;
  background: lightseagreen;
  color: white;
  padding: 2rem;
  border-radius: 0.3rem;
  box-shadow: 5px 10px lightgrey;
}
.statshead {
  text-transform: uppercase;
  // font-weight: bold;
  margin: 0;
}
.statsdetail {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
}
.percent {
  margin: 0;
}
</style>
