<template>
  <!--  -->
  <h1><span class="head">Jab</span><span class="light">trac</span></h1>
  <div id="nav">
    <router-link to="/">tracker</router-link> |
    <!-- <router-link to="/admin">admin</router-link> | -->
    <router-link to="/stats">statistics</router-link>
  </div>
  <router-view />
</template>

<style lang="scss">
body {
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // color: #2c3e50;
    color: #35517b;
    font-size: 14px;
    padding: 2rem 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.container {
  padding: 2rem 1rem;
  @media only screen and (min-width: 768px) {
    min-width: 90vw;
    max-width: 768px;
    min-height: 70vh;
    border: 1px solid lightseagreen;
    box-shadow: 5px 10px lightseagreen;
    border-radius: 0.2rem;
  }
}
.head {
  font-weight: bolder;
  text-transform: lowercase;
  font-size: 3rem;
  color: lightseagreen;
}
.light {
  font-weight: normal;
  font-size: 3rem;
  color: lightseagreen;
}
#nav {
  display: block;
  padding: 2rem;
  font-size: 1.2rem;

  a {
    font-weight: bold;
    color: #35517b;
    text-decoration: none;
    &:hover {
      color: lightseagreen;
    }

    &.router-link-exact-active {
      color: lightseagreen;
    }
  }
}
input[type="email"],
input[type="text"],
input[type="password"] {
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
    margin: 1rem 0rem;
  }
  width: 25rem;
  color: #35517b;
  text-align: center;
  font-weight: bold;
  height: 3rem;
  margin: 1rem;
  padding: 0.2rem 1rem;
  font-size: 1rem;
  border-style: solid;
  border-color: lightseagreen;
  border-width: 0px 0px 1px 0px;
  border-radius: 0.3rem;
  text-transform: lowercase;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-width: 0px 0px 5px 0px;
  }
}
textarea {
  color: #35517b;
  margin: 1rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  font-size: 1rem;
  border-style: solid;
  border-color: lightseagreen;
  border-width: 0px 0px 1px 0px;
  border-radius: 0.3rem;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-width: 0px 0px 5px 0px;
  }
}
.btn {
  // width: 100%;
  // display: block;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
  cursor: pointer;
  padding: 1rem 3rem;
  margin: 0.2rem;
  border-radius: 0.3rem;
  font-size: 1.5rem;
  font-weight: bold;
  &:hover {
    box-shadow: none;
    box-shadow: 5px 10px lightgrey;
  }
}
.outlined {
  border: 2px solid lightseagreen;
  background: white;
  color: lightseagreen;
}
.block {
  background: lightseagreen;
  color: white;
  border: none;
}

input[type="checkbox"],
input[type="radio"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
label {
  position: relative;
  display: inline-block;
  padding: 0 0 0 2em;
  height: 1.5em;
  line-height: 1.5;
  cursor: pointer;
  margin: 0.5rem;
  font-size: 1.5rem;
}
label::before,
label::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 1.5em;
  height: 1.5em;
}
label::before {
  content: " ";
  border: 2px solid lightseagreen;
  border-radius: 20%;
}
/* Checkbox */
input[type="checkbox"] + label::after {
  content: "\2714";
  color: lightseagreen;
  line-height: 1.5;
  text-align: center;
}
/* Radio */
input[type="radio"] + label::before {
  border-radius: 50%;
}
input[type="radio"] + label::after {
  content: " ";
  top: 0.25em;
  left: 0.25em;
  width: 1em;
  height: 1em;
  background: #fff;
  border: 0.2em solid lightgreen;
  border-radius: 50%;
}
/* :checked */
input[type="checkbox"]:checked + label::before,
input[type="radio"]:checked + label::before {
  background: #fff;
  border-color: #fff;
}
input[type="checkbox"] + label::after,
input[type="radio"] + label::after {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
}
input[type="checkbox"]:checked + label::after,
input[type="radio"]:checked + label::after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
/* Transition */
label::before,
label::after {
  -webkit-transition: 0.25s all ease;
  -o-transition: 0.25s all ease;
  transition: 0.25s all ease;
}
</style>
