import { createRouter, createWebHistory } from "vue-router";
import Tracker from "../views/Tracker.vue";

const routes = [
  {
    path: "/",
    name: "Tracker",
    component: Tracker,
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "adduser" */ "../views/Admin.vue"),
  },
  {
    path: "/stats",
    name: "Statistics",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "stats" */ "../views/Statistics.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
