import firebase from "firebase/app";
import "firebase/firestore";

// const settings = { timestampsInSnapshots: true };

const config = {
  apiKey: "AIzaSyDaFhnoptTtOIxCriPD5mMp0Y27biCdVuA",
  authDomain: "venzo-vaccination-tracker.firebaseapp.com",
  projectId: "venzo-vaccination-tracker",
  storageBucket: "venzo-vaccination-tracker.appspot.com",
  messagingSenderId: "578639519325",
  appId: "1:578639519325:web:08ad166d923dbde665f7b4",
};

const fbApp = firebase.initializeApp(config);

// firebase.firestore().settings(settings);
const db = fbApp.firestore();
const domainref = db.collection("domains");

export { fbApp, db, domainref };
